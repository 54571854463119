<template>
    <div class="MeusPedidos">
        <div class="container">
            <div class="col-12 col-xl-10 offset-xl-1">
                <div class="row">
                    <div class="col-12 mt-5 text-center titulo">
                        <h1>Meus pedidos</h1>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-10 offset-xl-1 mt-5">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-5 form-group">
                        <label for="pedido"
                            >Nº Pedido
                            <small
                                >(formato: BT-NNNNNN-AA-AAAAA-NN)</small
                            ></label
                        >
                        <input
                            type="text"
                            name="pedido"
                            class="form-control"
                            v-model="numeroPedido"
                            @keyup.enter="buscar"
                            v-maska="'AA-######-AA-AAAAA-##'"
                            id="pedido"
                        />
                        <div class="invalid-feedback d-block">
                            {{ erroPedido }}
                        </div>
                    </div>
                    <div class="col-12">
                        <button
                            @click="buscar"
                            class="btn btn-site mx-auto d-table mt-4"
                        >
                            Buscar
                        </button>
                    </div>

                    <loading
                        :active="isLoad"
                        :can-cancel="true"
                        :is-full-page="true"
                    />
                </div>

                <div class="row" v-if="existePedido">
                    <div class="col-12 mb-4">
                        <h2 class="mt-5">
                            <strong>Pedido: {{ pedido.codigo }}</strong>
                        </h2>
                    </div>
                    <div
                        class="
                            col-12 col-md-7
                            d-flex
                            mt-4
                            flex-column flex-md-row
                        "
                    >
                        <img
                            width="248"
                            height="248"
                            class="pe-4 pb-4"
                            :src="qrCodePix"
                            alt=""
                        />
                        <div>
                            <h3>Valor Total Pedido</h3>
                            <h4>
                                {{ pedido.valor.valor_total_pedido_formatado }}
                            </h4>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 mt-4">
                        <h3>Como Pagar?</h3>
                        <h5>
                            Utilize o seu aplicativo bancário para a leitura
                            desse QRCODE.
                        </h5>
                        <h5>Caso tenha problemas poderá usar a chave PIX:</h5>
                        <h2 class="text-center mt-4">
                            <strong>(11) 98195-4277</strong>
                        </h2>
                        <h5 class="mt-4">
                            No comentário da mensagem do PIX, afim de facilitar
                            mencione o código do seu pedido.
                        </h5>
                    </div>
                    <div class="col-12 5 mt-5">
                        <h2>
                            <strong
                                >Status Pagamento:
                                {{ pedido.status.pagamento }}</strong
                            >
                        </h2>
                        <h2>
                            <strong
                                >Status Pedido:
                                {{ pedido.status.pedido }}</strong
                            >
                        </h2>
                    </div>
                    <div class="col-12 mt-4">
                        <div class="row">
                            <div class="row">
                                <div class="col-12 col-md-7">
                                    <h2><strong>Cliente</strong></h2>
                                    <h5>
                                        <strong>Nome:</strong>
                                        {{ pedido.cliente.nome }}
                                    </h5>
                                    <h5>
                                        <strong>E-mail:</strong>
                                        {{ pedido.cliente.email }}
                                    </h5>
                                    <h5>
                                        <strong>Celular:</strong>
                                        {{ pedido.cliente.celular }}
                                    </h5>
                                </div>
                            </div>

                            <div class="col-12 col-md-7 mt-4">
                                <h2><strong>Itens Pedido</strong></h2>

                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Qtde</th>
                                            <th scope="col">Descrição</th>
                                            <th scope="col">Valor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="item in pedido.itens"
                                            :key="item.id"
                                        >
                                            <td>{{ item.qtde }}X</td>
                                            <td>{{ item.descricao }}</td>
                                            <td>
                                                {{
                                                    (item.valor * item.qtde)
                                                        | formatarMoedaBR
                                                }}
                                                <br />
                                                <div class="fonte_pequena">
                                                    {{
                                                        item.valor
                                                            | formatarMoedaBR
                                                    }}
                                                </div>
                                            </td>
                                        </tr>

                                        <tr class="text-center">
                                            <td></td>
                                            <td>
                                                <strong
                                                    >Valor Total
                                                    {{
                                                        pedido.valor
                                                            .total_formatado
                                                    }}</strong
                                                >
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-12 col-md-5">
                                <h2 class="mb-4"><strong>Entrega</strong></h2>

                                <div class="mb-4">
                                    <h5>
                                        <strong>Tipo Entrega:</strong>
                                        {{ pedido.entrega.descricao }}
                                    </h5>
                                    <h5
                                        v-if="
                                            pedido.entrega.id == 1 &&
                                            pedido.status.id_status_pedido < 3
                                        "
                                    >
                                        Após finalização do pedido para
                                        retirada, o endereço será informado.
                                    </h5>
                                </div>

                                <div v-if="pedido.entrega.id == 2">
                                    <h5>
                                        <strong>CEP:</strong>
                                        {{ pedido.cliente.endereco.cep }}
                                    </h5>
                                    <h5>
                                        <strong>Rua:</strong>
                                        {{ pedido.cliente.endereco.logradouro }}
                                    </h5>
                                    <h5>
                                        <strong>Número:</strong>
                                        {{ pedido.cliente.endereco.numero }}
                                    </h5>
                                    <h5>
                                        <strong>Complemento:</strong>
                                        {{
                                            pedido.cliente.endereco.complemento
                                        }}
                                    </h5>
                                    <h5>
                                        <strong>Bairro:</strong>
                                        {{ pedido.cliente.endereco.bairro }}
                                    </h5>
                                    <h5>
                                        <strong>Cidade:</strong>
                                        {{ pedido.cliente.endereco.cidade }}
                                    </h5>
                                    <h5>
                                        <strong>Estado:</strong>
                                        {{ pedido.cliente.endereco.estado }}
                                    </h5>
                                    <div class="mt-4">
                                        <h5>
                                            <strong>Valor Frete:</strong>
                                            {{ pedido.valor.frete_formatado }}
                                        </h5>
                                    </div>
                                </div>

                                <div
                                    v-if="
                                        pedido.status.id_status_pedido >= 4 &&
                                        pedido.status.id_status_pedido <= 5 &&
                                        pedido.entrega.id == 1
                                    "
                                >
                                    <h5 class="mb-4">
                                        Por favor, retire seu pedido no endereço
                                        abaixo:
                                    </h5>
                                    <h5><strong>CEP:</strong> 02273-010</h5>
                                    <h5>
                                        <strong>Rua:</strong> Avenida Paulo
                                        Lincoln do Valle Pontin
                                    </h5>
                                    <h5><strong>Número:</strong> 266</h5>
                                    <h5>
                                        <strong>Complemento:</strong>
                                        Apartamento 53
                                    </h5>
                                    <h5><strong>Bairro:</strong> Jacanã</h5>
                                    <h5><strong>Cidade:</strong> São Paulo</h5>
                                    <h5><strong>Estado:</strong> São Paulo</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosApi from "@/axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
    name: "MeusPedidos",
    components: { Loading },

    data() {
        return {
            pedido: {},
            numeroPedido: "",
            existePedido: false,
            isLoad: false,
            erroPedido: "",
            qrCodePix: "",
        };
    },

    methods: {
        validarPedido() {
            if (this.numeroPedido == "") {
                this.erroPedido = "Informe o número do pedido";
                return false;
            }

            if (this.numeroPedido.length < 20) {
                this.erroPedido =
                    "Preencha o formato BT-NNNNNN-AA-AAAAA-NN completo no pedido";
                return false;
            }

            return true;
        },

        async criarQrCodePIX(chave, valor, mensagem, identificador) {
            let headers = {
                headers: {
                    "ws-key-api": process.env.VUE_APP_API_WEBSLIM,
                },
            };

            let dados = {
                chave: chave,
                valor: valor,
                mensagem: mensagem,
                identificador: identificador,
            };

            let pix = await axiosApi.post(
                `${process.env.VUE_APP_URL_TOOLS}/pix/qrcode`,
                dados,
                headers
            );

            if (pix.data.success) {
                return pix.data.qrcode;
            } else {
                return "";
            }
        },

        async buscar() {
            let dados = { codigo: this.numeroPedido };
            this.erroPedido = "";
            this.existePedido = false;

            if (!this.validarPedido()) {
                return false;
            }

            try {
                this.isLoad = true;
                let response = await axiosApi.post("/pedido/buscar", dados);
                if (response.data.sucesso) {
                    this.pedido = response.data.pedido;

                    let pix = await this.criarQrCodePIX(
                        process.env.VUE_APP_PIX_CHAVE,
                        this.pedido.valor.valor_total_pedido,
                        `Referente ao pedido ${this.pedido.codigo}`,
                        this.pedido.codigo
                    );

                    if (pix) {
                        this.qrCodePix = pix;
                    }

                    this.existePedido = true;
                }
            } catch (error) {
                this.erroPedido = "O pedido informado não existe";
            } finally {
                this.isLoad = false;
            }
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
.MeusPedidos {
    @include background;
    @include form;

    .titulo {
        @include titulo;
    }
    .btn-site {
        @include btn-site;
    }

    table {
        color: $marrom;
        border-color: $marrom;
    }

    .fonte_pequena {
        font-size: 12px;
        font-style: italic;
    }

    h2 {
        color: $marrom;
        font-family: $roboto;
        font-weight: normal;
        font-style: normal;
        font-size: 22px;
        line-height: 22px;
    }
    h3 {
        color: $marrom;
        font-family: $roboto;
        font-weight: bold;
        font-style: normal;
        font-size: 22px;
        line-height: 22px;
    }
    h4 {
        color: $marrom;
        font-family: $roboto;
        font-weight: bold;
        font-style: normal;
        font-size: 29px;
        line-height: 29px;
    }
    h5 {
        color: $marrom;
        font-family: $roboto;
        font-weight: normal;
        font-style: normal;
        font-size: 18px;
        line-height: 22px;
    }

    .box {
        background-color: $marrom;
        h5 {
            color: $branco;
            margin: 0;
            font-size: 16px;
            line-height: 22px;
        }
    }
}
@media (max-width: 767.98px) {
    .MeusPedidos {
        padding: 50px 0 80px;
    }
}
</style>
